import React from 'react'
import Navbar from './Componets/Navbar'
import HomePage from './Componets/HomePage'
import Services from './Componets/Services'
import Providing from './Componets/Providing'
import Performance from './Componets/Performance'
import Portfolio from './Componets/Portfolio'
import Industry from './Componets/Industry'
import Testimonials from './Componets/Testimonials'
import Trends from './Componets/Trends'
import Footer from './Componets/Footer'
import { Helmet } from 'react-helmet'

const CandT = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Manufacturing || C & T Limitetion </title>
      </Helmet>
    <div>
        <Navbar/>
        <HomePage/>
        <Services/>
        <Providing/>
        <Performance/>
        <Portfolio/>
        <Industry/>
        <Testimonials/>
        <Trends/>
        <Footer/>
    </div>
    </>
  )
}

export default CandT