import React from "react";
import Footer from "./Componets/Footer";
import Nabvar from "./Componets/Nabvar";
import { Helmet } from "react-helmet";
import Home from "./Componets/Home";
import Manufactory from "./Componets/Manufactory";
import Choose from "./Componets/Choose";
import Projects from "./Componets/Projects";
import Feedback from "./Componets/Feedback";
import Quote from "./Componets/Quote";
import Blog from "./Componets/Blog";

const ManuSys = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Manufacturing || Manu sys </title>
      </Helmet>
      <div>
        <Nabvar />
        <Home />
        <Manufactory />
        <Choose />
        <Projects />
        <Feedback />
        <Quote />
        <Blog />
        <Footer />
      </div>
    </div>
  );
};

export default ManuSys;
