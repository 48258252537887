
import React from 'react';
import qImg from '../Images/q.png'

const Text = () => {
    return (
      
            <div className="bg-[#cccccc] h-[500px] bg-center bg-no-repeat bg-cover relative bg-hero1">
  <div className="text-center absolute -translate-x-2/4 -translate-y-2/4 text-[white] left-2/4 top-2/4">
    <p className='mb-[30px]' >Industro is a company that we can rely on. We often have to react to </p>
    <p className='mb-[30px]'>customer needs quickly and we know that Industro will take care of us even at </p>
    <p className='mb-[30px]'>a moment’s notice.</p>
  <img className='md:ml-40' src={qImg} alt="" />
  </div>

        </div>
    );
};

export default Text;