import React from "react";

const HomePage = () => {
  return (
    <div>
      {/* Header start  */}
      <div
        className="selection:bg-Green selection:text-white mx-auto w-full 
        md:h-[35rem]  h-96 py-10 px-4 bg-Ct bg-cover md:bg-top bg-center"
      >
        {/* Content Section */}
        <div
          className="flex flex-col justify-center 
        text-left items-left md:ml-40 ml-0 md:h-[30rem] h-96 mx-auto"
        >
          <small className="text-orange-600 font-extrabold">
            Positive Results From A Fast Growing Industrial & Manufacturing
            Estates
          </small>
          <h1 className="text-white uppercase font-bold py-2 md:text-4xl text-xl mb-3">
            Industrial Solutions <br /> With Best Machinery!{" "}
          </h1>
          <p className=" text-center text-white font-subheading   md:text-lg text-xs md:w-[980px] max-w-fit">
            We produce positive results from ever-growing Industrial &
            manufacturing estates,
            <br /> we have established a corporate mandate to maintain strong
            core values.
          </p>
          <div className="flex gap-10 md:my-5 my-3">
            <button
              className="bg-orange-600 text-white
                font-bold p-4 rounded-md hover:bg-white hover:text-black"
            >
              More About Us
            </button>
            <button
              className="bg-white text-black p-4 rounded-md font-bold
                     hover:bg-orange-600 hover:text-white"
            >
              Aour Service
            </button>
          </div>
        </div>
        {/* Content Section Ends*/}
      </div>

      {/* Header End  */}
    </div>
  );
};

export default HomePage;
