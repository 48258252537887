import React from "react";
import { Helmet } from "react-helmet";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import News from "./Components/News";
import Page from "./Components/Page";
import Text from "./Components/Text";
const Menufecturing = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Manufacturing || Manufacturing </title>
      </Helmet>
      <div>
        <Header />
        <About />
        <Page />
        <Text />
        <News />
        <Footer />
      </div>
    </div>
  );
};

export default Menufecturing;
