import logo from './logo.svg';
import './App.css';
import { BrowserRouter  ,  Routes,Route} from "react-router-dom"
import Notfound from './Notfound';
import Manufacturing from './Manufacturing';
import CandT from './CandT/CandT';
import ManuSys from './Manu-Sys/Manu-Sys';
import Menufecturing from './Menufec/Menufecturing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>

        <Route path="/" element={<Manufacturing/>}/>
         <Route path="/industrial-solutions" element={<CandT/>}/>
       <Route path="/buysteel" element={<ManuSys/>}/>
         <Route path="/sustainable-infrastructure" element={<Menufecturing/>}/> 
        <Route path="*" element={<Notfound />} />
    
</Routes>
</BrowserRouter>
    </div>
  );
}

export default App;
