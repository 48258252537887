import React from "react";

const Providing = () => {
  return (
    <div>
      <div className=" flex  md:flex-row flex-col items-end md:my-10 my-5">
        <div
          className="bg-banner bg-no-repeat md:w-[800px]
         w-96 md:h-[500px] h-96  bg-cover"
        >
          <div className="bg-orange-600 md:w-60 absolute flex gap-5 
          flex-col text-sm md:my-72 my-20 py-5 px-5 text-white ">
            <h1>We Are Open For Opportunities!</h1>
            <p>
              Building relationships and portfolio that last. Serving an
              impressive list of long-term clients with experience and expertise
              in industries.
            </p>
            <div>View Our Works</div>
          </div>
        </div>
        <div className="md:w-[700px] w-96  bg-orange-600 text-white font-bold ">
          <h1 className="font-extrabold text-4xl md:my-5 my-5 md:py-2 py-5 md:px-5 px-10">
            Providing Full Range Of High <br /> <br /> Services &amp; Soultions
            Worldwide.
          </h1>
          <p className="md:py-2 py-5 md:px-10 px-10">
            As a diversified construction management, design-build, and general
            contracting firm, Axima Group is recognized as one of the World's
            leading Industry and Manufacturing Corporation!
          </p>

          <div className="flex md:flex-row flex-col md:py-2 py-5 text-sm  md:px-10 px-10 ">
            <div className="flex  flex-col gap-5 ">
              <div>
                <i class="fa-solid fa-dumpster-fire text-5xl"></i>
              </div>
              <div>Environmental Sensitivity</div>
              <div className="">
                The world of international supply chains involves a myriad of
                unknown risks and challenging regulations.
              </div>
            </div>
            <div className="flex  flex-col gap-5 ">
              <div>
                <i class="fa-solid fa-dumpster-fire text-5xl"></i>
              </div>
              <div>Environmental Sensitivity</div>
              <div className="">
                The world of international supply chains involves a myriad of
                unknown risks and challenging regulations.
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center md:my-10">
            <button
              className="border-2 rounded-xl border-white text-white hover:bg-white 
            p-5 hover:text-orange-600"
            >
              Schedule An Appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providing;
