import React from "react";
import Imge1 from "../images/video-banner-1.jpg";
import testi1 from "../images/testi1.jpg";
const Feedback = () => {
  return (
    <>
      <div className=" flex md:flex-row flex-col justify-center items-center gap-20 md:my-10 my-5">
        <div className="md:w-[600px] w-80">
          <img src={Imge1} alt="" />
        </div>
        <div className="md:w-[600px] w-80 text-center flex flex-col gap-10  ">
          <h1 className="font-bold text-3xl">Customer Feedback</h1>
          <p>
            We help clients find ways to turn everyday information into
            actionable insights by embedding work analytics across their
            organization’s strategy &all systems.
          </p>
          <div className="flex flex-col justify-center items-center">
            <img src={testi1} alt="" className="rounded-full" />
            <p className="font-bold">Thomas Bennet</p>
            <p>Newyork</p>
          </div>
          <p>
            We help clients find ways to turn everyday information into
            actionable insights by embedding work analytics across their
            organization’s strategy &all systems.
          </p>
        </div>
      </div>
    </>
  );
};

export default Feedback;
