import React from 'react';
import aboutImg from '../Images/about.png'

const About = () => {
    return (
      <div className="bg-white pt-[90px] pb-10">
      <div className="container max-w-full mx-auto sm:px-4">
         <div className="flex flex-wrap  d_flex">
            <div className="md:w-3/5 pr-4 pl-4">
               <div className="max-w-[666px] float-right w-full text-left pb-0">
                  <h2 className='text-5xl font-bold pb-10'>Area of Work</h2>
                  <span className='pt-10 pb-[60px]'>Industro has 50+ years’ experience in providing companies with a wide array of specialty works. Listed below are just some of the services we offer to our clients.</span>
               
                  {/* <a className="read_more" href="Javascript:void(0)"> Read More</a> */}
               </div>

            </div>
           
            <div className="md:w-1/3 pr-4 pl-4">
                  <div className="pl-[55px] pr-10">
                  <figure className='m-0'><img className='w-full' src={aboutImg} alt="#"/></figure>
               </div>
            </div>
           
            <div className='"md:w-1/3 pr-4 pl-4'>
                     <div className='max-w-[666px] float-right w-full text-left pb-10' >
                        <h3 className='text-[#4555B9] text-2xl text-center pb-8 font-semibold'>Chemicals and Plastics</h3>
                  <p className='text-lg'>Industro provides chemicals and plastics through several operations including production of olefins, polyolefins, aromatics, styrenics, and specialty products

                  </p>
                  </div>
                  </div>
                  <div className='"md:w-1/3 pr-4 pl-4'>
                     <div className='max-w-[666px] float-right w-full text-left pb-10' >
                        <h3 className='text-[#4555B9] text-2xl text-center pb-8 font-semibold'>Steel and Oil Refining</h3>
                  <p className='text-lg'>Oil and steel refinery are the most important spheres of our company operation. They play a crucial role in development of modern technologies and industries.

                  </p>
                  </div>
                  </div>
                  <div className='"md:w-1/3 pr-4 pl-4'>
                     <div className='max-w-[666px] float-right w-full text-left pb-0' >
                        <h3 className='text-[#4555B9] text-2xl text-center pb-8 font-semibold'>Mining</h3>
                  <p className='text-lg'>Modern mining processes, supported by our company, involve prospecting for ore bodies, analysis of the profit potential of a mine, and other activities.

                  </p>
                  </div>
                  </div>
                  <div className='"md:w-1/3 pr-4 pl-4'>
                     <div className='max-w-[666px] float-right w-full text-left pb-0' >
                        <h3 className='text-[#4555B9] text-2xl text-center pb-8 font-semibold'>Industrial Machinery</h3>
                  <p className='text-lg'>As a vast sphere of Industro’s activity, industrial machinery covers different subareas of our work including heavy equipment and hardware production.

                  </p>
                
                  </div>
                  </div>
           
            
         </div>
      </div>

   </div>
    );
};

export default About;