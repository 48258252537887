import React from "react";
import headerImg from "../images/Group 8365.png";
import {
  RiFacebookFill,
  RiTwitterFill,
  RiSkypeFill,
  RiLinkedinFill,
} from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
const Nabvar = () => {
  return (
    <>
      <div className=" flex- justify-center items-center my-10 ">
        <div className="max-w-[1280px]">
          <div className=" flex md:flex-row flex-col justify-around items-center md:mx-20 ">
            <div>
              <img src={headerImg} alt="Induscity" />
            </div>

            <div className="flex flex-col md:block hidden">
              <div className="font-bold">Top Most Factory</div>
              <div>In United States</div>
            </div>
            <div className="flex flex-col md:block hidden">
              <div className="font-bold">Top Most Factory</div>
              <div>In United States</div>
            </div>
            <div className="flex flex-col md:block hidden">
              <div className="font-bold">Top Most Factory</div>
              <div>In United States</div>
            </div>
            <div className="md:block hidden     ">
              <div className=" flex gap-5 ">
                <RiFacebookFill />
                <RiTwitterFill />
                <RiSkypeFill />
                <RiLinkedinFill />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------- */}
      <div className=" md:block hidden">
        <div className=" text-white flex justify-center items-end  ">
          <div className=" flex gap-20 bg-black md:py-5 md:px-10">
            <div>HOME</div>
            <div>ABOUT US</div>
            <div>PAGES</div>
            <div>MARKET SECTORS</div>
            <div>PROJECTS</div>
            <div>SHOP</div>
            <div>CONTACT</div>
            <div>
              <BiSearch />
            </div>
          </div>
          <div className="bg-yellow-300 md:px-10 md:py-5 ">Get A Quote</div>
        </div>
      </div>
    </>
  );
};

export default Nabvar;
