import React from 'react';
import pageImg from '../Images/p.png'

const Page = () => {
    return (
        <div className='' >
            <img className='w-[100vw]' src={pageImg} alt="" />
        </div>
    );
};

export default Page;