import React from "react";

const Industry = () => {
  return (
    <>
      <div className="' flex justify-center items-center">
        <div className="max-w-[1200px]">
          <div className="flex md:flex-row flex-col gap-10 md:my-20 my-10">
            <div className=" flex flex-col gap-10 md:my-10 my-5 md:text-start text-center">
              <p className="text-orange-600 font-bold">
                Innovation From The Ground To The Skies.
              </p>
              <div className="font-bold text-4xl">
                Customized Solutions Fits Every Industry Needs!
              </div>
              <p>
                Yet those that embrace change are thriving, building bigger,
                better, faster, and stronger products than ever before, We can
                help build on your past successes and prepare for \ future.
                Axima Group is one of the leading Industry.
              </p>
              <div>
                <button className=" border-orange-600 border-2 p-5 rounded-lg hover:bg-orange-600">
                  More Abou us
                </button>
              </div>
            </div>
            <div>
              <div
                className="bg-images2 bg-no-repeat md:w-[500px]
         w-96 md:h-[400px] h-96  bg-cover"
              >
                <div
                  className="bg-orange-600 md:w-96 absolute flex gap-5 
          flex-col text-sm md:my-80 my-20 py-5 px-5 md:-ml-20 text-white "
                >
                  <h1 className="font-bold text-xl">
                    Join Us And Be Part Of <br /> Our Team!
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Industry;
