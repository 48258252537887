import React from "react";
import service1 from "../images/service1.jpg";
import service2 from "../images/service2.jpg";
import service3 from "../images/service3.jpg";
import service4 from "../images/service4.jpg";
import service5 from "../images/service5.jpg";
import service6 from "../images/service6.jpg";
import { BsLink45Deg } from "react-icons/bs";
const Manufactory = () => {
  return (
    <>
      <div className="flex justify-center items-center md:mx-20 mx-10 md:my-20 my-10">
        <div className="flex md:flex-row flex-col gap-10">
          <div>
            <p className="text-yellow-300 text-xl font-bold">Welcome to</p>
            <h1 className=" font-bold text-3xl">Manufactory</h1>
            <hr className="fill-yellow-300 text-yellow-300 text-2xl" />
            <p>
              When you give to Our Charity, know your donation is making a
              difference Whether you are supporting one our Signature Programs
              or our carefully of our professional staff.
            </p>
          </div>
          <div className="flex flex-col gap-10">
            <h1 className="font-bold text-xl">Punctual Delivery Time</h1>
            <p>
              How this mistaken idea denouncing pleasure and praising pain was
              born will give you a complete.
            </p>
          </div>
          <div className="flex flex-col gap-10">
            <h1 className="font-bold text-xl">Smart Technology</h1>
            <p>
              The master of human happiness no one rejects, dislikes avoids
              pleasure itself, because it is pleasure.
            </p>
          </div>
          <div className="flex flex-col gap-10">
            <h1 className="font-bold text-xl">Team Of Professionals</h1>
            <p>
              Again is there anyone who loves or pursues and desires obtain pain
              itself, because it is pain occasionally.
            </p>
          </div>
        </div>{" "}
      </div>

      {/* iamges card  start  */}

      <div>
        <div className="flex md:flex-row flex-col justify-center items-center flex-wrap gap-20 md:mx-5 mx-5 md:mt-20 md:my-10">
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700"
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service1} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Power and Energy
              </h5>
              <p class="mb-4 text-black w-72 ">
                Great explorer of the truth sed, the master builder of human
                happiness.
              </p>
            </div>
          </div>
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700
                "
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service2} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Construction &Engineering
              </h5>
              <p class="mb-4 text-black w-72 ">
                Explain to you how all this mistaken idea of denouncing pleasure
                praising.
              </p>
            </div>
          </div>
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700 "
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service3} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Ship Building Industry
              </h5>
              <p class="mb-4 text-black w-72 ">
                How all this mistaken idea of denouncing pleasure and praising
                complete.
              </p>
            </div>
          </div>
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700"
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service4} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg  bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Aero Space Services
              </h5>
              <p class="mb-4 text-black w-72 ">
                The expound actual teachings sed the all great explorer of the
                truth.
              </p>
            </div>
          </div>
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700 "
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service5} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Automative System
              </h5>
              <p class="mb-4 text-black w-72 ">
                Powefull extreamly and again is there all who loves pursues or
                desires.
              </p>
            </div>
          </div>
          <div
            class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700 "
          >
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg" src={service6} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                >
                  <BsLink45Deg className=" fill-white text-2xl" />
                </div>
              </a>
            </div>
            <div class="p-6 md:ml-10  hover:-ml-3 duration-700 text-center  ">
              <h5
                class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
              >
                Railway Infrastructure
              </h5>
              <p class="mb-4 text-black w-72 ">
                Physical exercise, except too obtain some advantage from it any
                right.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* iamges card  End  */}
    </>
  );
};

export default Manufactory;
