import React from "react";
import img1 from "../images/clin.png";

import { BsFillArrowRightCircleFill } from "react-icons/bs";
const Performance = () => {
  return (
    <>
      <div className=" flex justify-center items-center">
        <div
          className="flex md:flex-row flex-col gap-10
       md:mx-20 mx-10 md:my-20 my-5"
        >
          <div className="flex  flex-col gap-5">
            <div className="font-bold text-red-500">
              Fast Growing Industrial & Manufacturing Estates.
            </div>
            <div className=" font-bold text-5xl">
              Provides High Performance Services For Multiple Industries And
              Technologies!
            </div>
          </div>
          <div className=" flex flex-col gap-10">
            <div>
              Yet those that embrace change are thriving, building bigger,
              better, faster, and stronger products than ever before. You are
              helping to lead the charge; we can help you build on your past
              successes and prepare for future.
            </div>
            <div className=" flex md:flex-row flex-col gap-10">
              <button
                className="bg-orange-600 md:px-10 px-5  rounded-md text-white
        
            shadow-2xl hover:bg-blue-950"
              >
                our service
              </button>
              <div className="flex gap-10">
                <p>
                  {" "}
                  <img
                    src={img1}
                    alt=""
                    className="border-2 border-orange-600 rounded-full"
                  />
                </p>
                <p className="flex flex-col gap-5">
                  <small>(002) 01061245741</small>
                  <small>Sales Representative</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* card start  */}

      <div className=" flex justify-center items-center">
        <div className=" max-w-[1000px]">
          <div className="flex md:flex-row flex-col gap-10 ">
            <div className=" bg-white flex flex-col gap-10 shadow-2xl 
            md:px-10 px-5 md:py-10 py-5 md:mx-0 mx-5">
              <h1 className="font-bold text-2xl">
                Petroleum & Gas <br />
                Energy
              </h1>
              <hr className="text-2xl" />
              <p className="text-justify">
                Petroleum and natural gas are nonrenewable sources of energy, a
                liquid found underground that can be used.
              </p>
              <p className="flex items-center  gap-5">
                <span>
                  <BsFillArrowRightCircleFill className="font-bold text-xl" />
                </span>
                <span className="font-bold text-lg">Read More</span>
              </p>
            </div>
            <div className=" bg-white flex flex-col gap-10 shadow-2xl 
            md:px-10 px-5 md:py-10 py-5  md:mx-0 mx-5">
              <h1 className="font-bold text-2xl">
                Petroleum & Gas <br />
                Energy
              </h1>
              <hr className="text-2xl" />
              <p className="text-justify">
                Petroleum and natural gas are nonrenewable sources of energy, a
                liquid found underground that can be used.
              </p>
              <p className="flex items-center  gap-5">
                <span>
                  <BsFillArrowRightCircleFill className="font-bold text-xl" />
                </span>
                <span className="font-bold text-lg">Read More</span>
              </p>
            </div>
            <div className=" bg-white flex flex-col gap-10 shadow-2xl 
            md:px-10 px-5 md:py-10 py-5  md:mx-0 mx-5">
              <h1 className="font-bold text-2xl">
                Petroleum & Gas <br />
                Energy
              </h1>
              <hr className="text-2xl" />
              <p className="text-justify">
                Petroleum and natural gas are nonrenewable sources of energy, a
                liquid found underground that can be used.
              </p>
              <p className="flex items-center  gap-5">
                <span>
                  <BsFillArrowRightCircleFill className="font-bold text-xl" />
                </span>
                <span className="font-bold text-lg">Read More</span>
              </p>
            </div>
            
          </div>
        </div>
      </div>


      {/* imag start  */}

      <div
          className="bg-images1 bg-no-repeat w-full
         w-96 md:h-[500px] h-96  bg-cover my-10"
        >
          <div className="bg-orange-600 md:w-96 absolute flex gap-5 
          flex-col text-sm md:my-96 my-20 py-5 px-5 right-10 text-white ">
            <h1>Dedicated Customer </h1>
            <h1> Teams & An Agile Services </h1>
            <p>
            Building relationships and portfolio that last. Serving an impressive list of long-term 
            clients with experience and expertise in industries.
            </p>
            <div className="flex gap-2">
                <div>
                 <BsFillArrowRightCircleFill className="font-bold text-xl fill-white" />   
                </div>
            <div>View Our Works</div>
            </div>
            
          </div>
        </div>
    </>
  );
};

export default Performance;
