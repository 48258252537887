import React from "react";

const Quote = () => {
  return (
    <>
      <div>
        <div className="bg-white shadow-2xl flex flex-col justify-center items-center  md:py-10 py-5 md:px-2 px-10">
          <h2 className="text-center text-4xl font-bold underline">Request a Quote</h2>

          <div className="flex md:flex-row flex-col gap-10 my-10 ">
            <div className=" md:w-[600px] w-80 flex flex-col gap-20">
              <p>
               <span className="text-yellow-300 font-bold">For Business:</span> Do you have questions about how Manufactory can
                help your company? Send us an email and we’ll get in touch
                shortly, or phone 1800 234 567 between 07:30 and 19:00 Monday to
                Friday — we would be delighted to speak.
              </p>
              <p>
              <span className="text-yellow-300 font-bold"> Note:   </span>  Your details are kept strictly confidential as per our
                Company Privacy Policy.
              </p>
            </div>
            <div className=" md:w-[600px] w-80 flex flex-col gap-10">
              <h1 className=" font-bold text-lg">Select Needed Service</h1>
              <div>
                <input type="text" className=" md:w-[500px] w-80 md:py-2 py-2 border-2 rounded-md border-yellow-300" />
              </div>
              <div className="flex md:flex-row flex-col gap-5">
                <input type="text" className=" md:w-[240px] w-80 md:py-2 py-2 border-2 rounded-md border-yellow-300"/>
                <input type="text" className=" md:w-[240px] w-80 md:py-2 py-2 border-2 rounded-md border-yellow-300"/>
              </div>
              <div className="flex p-5 rounded-md text-white text-center w-fit bg-yellow-300">
                Get A Quote
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
