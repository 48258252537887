import React from 'react';
import headerImg from '../Images/div.png'

const Header = () => {
    return (
        <header className=" h-screen bg-cover bg-no-repeat bg-hero">
       <div className="w-full h-[116px]">
           
       <div className="container mx-auto sm:px-4">
              <div className="flex flex-wrap ">
                 <div className="md:w-full pr-4 pl-4">
                    <div className="h-[100px] m-10 p-[25px]">
                       <div className="flex flex-wrap ">
                          <div className="xl:w-1/4  lg:w-1/4  md:w-1/4  sm:w-1/4 pr-4 pl-4 relative flex-grow md:max-w-full flex-1 px-4 ">
                             <div className=" md:w-full float-left m-0 p-0">
                                <div className="">
                                   <div className=''>
                                    
                                        <img src={headerImg} alt="" />
                                       
                                   </div>
                                </div>
                             </div>
                          </div>
                          <div className="xl:w-3/4  lg:w-3/4  md:w-3/4  sm:w-3/4 pr-4 pl-4">
                             <nav className="navigation relative flex flex-wrap items-center content-between py-3 px-4  text-white ">
                            
                                <div className="md:block hidden flex-grow items-center" id="navbarsExample04">
                                   <ul className="flex flex-wrap list-reset pl-0 mb-0 mr-auto text-white">
                                      <li className=" text-[#FFBA00] border-b-[5px] border-b-[#FFBA00] border-solid">
                                         <a className="inline-block py-2 px-4 no-underline" href="l">HOME</a>
                                      </li>
                                      <li className="">
                                         <div className="inline-block py-2 px-4 no-underline" >About</div>
                                       
                                      </li>
                                      <li className="">
                                         <div className="inline-block py-2 px-4 no-underline" >PAGE</div>
                                      </li>
                                      <li className="">
                                         <div className="inline-block py-2 px-4 no-underline" >BLOG</div>
                                      </li>
                                      <li className="">
                                         <div className="inline-block py-2 px-4 no-underline" >GALLERY</div>
                                      </li>
                                      <li className="">
                                         <div className="inline-block py-2 px-4 no-underline" >CONTACTS</div>
                                      </li>
                                   </ul>
                                </div>
                                <ul className="pl-2.5 pt-2.5">
                                   <li><div ><i className="fa fa-search" aria-hidden="true"></i></div></li>
                                </ul>
                                
                             </nav>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <section className="banner_main">
           
            
      
                
                    <div className="container mx-auto sm:px-4 pl-4">
                       {/* <div className="carousel-caption  banner_po"> */}
                          <div className="flex flex-wrap ">
                             <div className="md:w-3/4 pr-4 md:pl-40">
                                <div className="text-left pt-[20%] ">
                                    <h5 className='text-[white] text-3xl'>Let Us Help You Invest</h5>
                                   <h1 className=' text-[40px] font-bold text-white md:leading-[90px] block pb-5'>In Sustainable Infrastructure</h1>
                                   <p className='text-white text-xl leading-[30px] font-[normal] mb-[55px] md:block hidden'>Our company provides world-class fabrication and construction services to industrial clients across the United States in order to invest and create reliable infrastructure.<br/> There are many variations of passages of</p>
                                   <div className="text-[17px]  text-white w-full  text-center inline-block transition-[ease-in] duration-[all] delay-[0.5s] font-medium h-[60px]  rounded-[5px] text-xl uppercase font-[normal] max-w-[278px] mr-[50px] mb-10 px-0 py-[15px] bg-[#ffba00] hover:bg-black"  role="button">VIEW CATALOG</div>
                                   <div className="text-[17px]  text-white w-full  text-center inline-block transition-[ease-in] duration-[all] delay-[0.5s] font-medium h-[60px]  rounded-[5px] text-xl uppercase font-[normal] max-w-[278px] mr-[50px] mb-10 px-0 py-[15px] bg-[#ffba00] hover:bg-black"  role="button">LEARN MORE</div>
                                </div>
                             {/* </div> */}
                          </div>
                       </div>
                    </div>
             
      
              
        
         
        </section>
        </header>
    );
};

export default Header;