import React from "react";
import tes1 from "../images/1.png.c09d4f9816c40f34fce1.png";
const Testimonials = () => {
  return (
    <>
      <div className="bg-orange-600">
        <div className="' flex justify-center items-center">
          <div className="max-w-[1200px]">
            <div className="flex md:flex-row flex-col gap-10">
              <div className=" bg-white shadow-2xl md:py-10 py-5 
              md:px-10 px-5  md:my-20 my-10 flex flex-col gap-10 rounded-lg">
                <div className="md:ml-10">
                    <h1 className="font-bold text-2xl">Get In Touch</h1> <br />
                <p className=" text-lg">
                  Complete control over products allows us to ensure our
                  customers receive the best quality prices and service.
                </p>  
                </div>
              
            

          
                <div className="md:w-[700px] w-96 flex md:flex-row flex-col justify-center items-center gap-5">
                  <input
                    type="text"
                    className=" md:w-[300px] w-96 md:py-5 py-5 border-2 border-orange-600 rounded-lg"
                    placeholder="Name"
                  />
                  <input
                    type="text"
                    className="md:w-[300px] w-96 md:py-5 py-5 border-2 border-orange-600 rounded-lg"
                    placeholder="Email"
                  />
                </div>
                <div className="md:w-[700px] w-96 flex md:flex-row flex-col justify-center items-center gap-5">
                  <input
                    type="text"
                    className=" md:w-[300px] w-96 md:py-5 py-5 border-2 border-orange-600 rounded-lg"
                    placeholder="Phone"
                  />
                  <select
                    type="text"
                    className=" md:w-[300px] w-96 md:py-5 py-5 border-2 border-orange-600 rounded-lg"
                    placeholder="selct your indsry"
                  >
                    <option value="1">Insrty 1</option>
                    <option value="2">Insrty 2</option>
                  </select>
                </div>
                <div className="flex justify-center items-center">
                  <textarea
                    name=""
                    id=""
                    cols="10"
                    rows="5"
                    className="md:w-[600px] w-96  md:py-5 py-5 border-2 
                     border-orange-600 rounded-lg"
                    placeholder="message"
                  ></textarea>
                </div>
           <div className="bg-orange-600 text-center md:px-10 px-5
            md:mx-20 mx-10 md:py-5 py-5 rounded-lg hover:bg-blue-500 duration-1000">
            <button className=" 
            
            text-white font-bold"> Submit</button>
           </div>

              </div>
              {/* second  */}
              <div className="flex flex-col gap-10 md:my-20 my-10 md:mx-8 mx-10">
                <p className="font-bold text-xl">Testimonials</p>

                <div className="font-bold text-4xl text-white">
                  I’ve seen great companies serving industry solutions in my
                  career. But not to the point where you feel that comfort and
                  trust that we get with Industic Company.
                </div>
                <div className="flex flex-col gap-10 ">
                  <p>
                    <img src={tes1} alt="" />
                  </p>
                  <p className="font-bold text-lg text-white">Martin Hope</p>
                  <p className="font-bold text-lg text-white">Pro Dust</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
