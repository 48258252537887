import React from 'react';
import tImg from '../Images/c1.png'
import tImg1 from '../Images/c.png'
import tImg2 from '../Images/c2.png'

const News = () => {
    return (
      <div className='pb-10'>
          <div className='ml-[30px] border-l-[5px] border-l-[#FFBA00] border-solid pb-10'>
          <h3 className='text-[black] ml-5 mr-[30px] mt-[30px] mb-0'>Latest Company News</h3>
          </div>
          <div className='flex md:flex-row flex-col '>
           
  <div className="hover:border-2 hover:border-black">
   
      <img src={tImg} alt="Cinque Terre" />

    {/* <div className="desc">Add a description of the image here</div> */}

</div>



  <div className="hover:border-2 hover:border-black">
 
      <img src={tImg1} alt="Forest" />
   
    {/* <div className="desc">Industro Reaches Agreement to Sell Baltimore Specialty Plant</div> */}

</div>


  <div className="hover:border-2 hover:border-black">

      <img src={tImg2} alt="Northern Lights" />
  
    {/* <div className="desc">Add a description of the image here</div> */}

</div>

  <div className="hover:border-2 hover:border-black">

      <img src={tImg} alt="Northern Lights" />
    

</div>
</div>


<div className="clearfix"></div>
        </div>
    );
};

export default News;