import React from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Image1 from "../images/a (1).088d7f5e6df5df4d6ef1.jpg";
const Trends = () => {
  return (
    <div>
      <div>
        {/* heading  */}
        <div className="text-center md:my-10 my-5">
          <p className="text-orange-600">Latest Articles Updated Daily</p>
          <div className="md:text-4xl text-2xl font-bold md:my-5 ">
            Latest From Blog
          </div>
          <p className="">
            Follow our latest news and thoughts which focuses <br /> exclusively
            on insight, industry trends, top news headlines.
          </p>
        </div>
        {/* heading  */}
        <div className="flex justify-center items-center">
          <div className="max-w-[1200px]">
            <div className=" flex md:flex-row flex-col gap-10">
              <div>
                <div>
                  <img
                    src={Image1}
                    alt=""
                    className="md:w-[300px] w-96 md:h-[300px] scale-90 hover:scale-100 duration-700  ml-5 h-96"
                  />
                </div>
                <div className="flex flex-col gap-5 my-5 mx-5">
                  <span>
                    <a href="" className="text-orange-600">
                      Oil & GasInsights Jan
                    </a>{" "}
                    20, 2019
                  </span>
                  <p className="font-bold text-2xl">
                    Importers Achieve Cost Savings Through The First Sale Rule!
                  </p>
                  <div className="flex gap-2">
                    <div>
                      <BsFillArrowRightCircleFill className="font-bold text-xl " />
                    </div>
                    <div>Read More</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image1}
                    alt=""
                    className="md:w-[300px] ml-5 w-96 md:h-[300px] h-96 scale-90 hover:scale-100 duration-700"
                  />
                </div>

                <div className="flex flex-col gap-5 my-5 mx-5">
                  <span>
                    <a href="" className="text-orange-600">
                      Oil & GasInsights Jan
                    </a>{" "}
                    20, 2019
                  </span>
                  <p className="font-bold text-2xl ">
                    Importers Achieve Cost Savings Through The First Sale Rule!
                  </p>
                  <div className="flex gap-2">
                    <div>
                      <BsFillArrowRightCircleFill className="font-bold text-xl " />
                    </div>
                    <div>Read More</div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <img
                    src={Image1}
                    alt=""
                    className="md:w-[300px] ml-5 w-96 md:h-[300px] h-96 scale-90 hover:scale-100 duration-700"
                  />
                </div>
                <div className="flex flex-col gap-5 my-5 mx-5">
                  <span>
                    <a href="" className="text-orange-600">
                      Oil & GasInsights Jan
                    </a>{" "}
                    20, 2019
                  </span>
                  <p className="font-bold text-2xl">
                    Importers Achieve Cost Savings Through The First Sale Rule!
                  </p>
                  <div className="flex gap-2">
                    <div>
                      <BsFillArrowRightCircleFill className="font-bold text-xl " />
                    </div>
                    <div>Read More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;
