import React from 'react'

const Home = () => {
  return (
    <div>
           <div
        className="selection:bg-Green selection:text-white mx-auto w-full 
        md:h-[45rem]  h-96 py-10 px-4 bg-images3 bg-cover md:bg-top bg-center"
      >
       
      
      </div> 


    </div>
  )
}

export default Home