import React from "react";
import headerImg from '../images/Group 8344.png';
const Navbar = () => {
  return (
    <div>
      <div className=" flex justify-evenly items-center md:py-5 py-5 shadow-xl">
        <div>
          <img src={headerImg} alt="" />
        </div>
        <div className="md:block hidden">
          <ul className="justify-evenly items-center flex gap-10 font-bold text-lg">
            <li>Home</li>
            <li>Company</li>
            <li>Industries</li>
            <li>Case Studies</li>
            <li>News</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="">
        
        </div>
      </div>
    </div>
  );
};

export default Navbar;
