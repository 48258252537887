import React from "react";
import aboutImg from "../images/2.jpg";

const Services = () => {
  return (
    <div>
      <div
        className="flex flex-col justify-center 
        text-left items-left md:ml-40 ml-0 md:h-[30rem] h-96 mx-auto md:px-10 px-10"
      >
        <small className="text-orange-600 font-extrabold">
          Setting A Global Standard In Industrial Contracting.
        </small>
        <h1 className=" uppercase font-bold py-2 md:text-4xl text-xl mb-3">
          Provides High Performance Services For <br /> Multiple Industries And
          Technologies!
        </h1>
      </div>

      {/* contets  */}
    

     

     
      <div className=" flex  justify-center items-center gap-10 md:mx-10 mx-5 ">
       <div className="max-w-[1280px] ">
       <div className="flex md:flex-row flex-col justify-center items-center gap-10 md:mx-10 mx-5  ">
       
        <div className="md:w-[600px] w-96 items-center">
            <div className="bg-orange-600 absolute md:mt-52 mt-28 ml-44 p-10">
            <h5 className="">Latest Solutions, And <br/>
                   Decades Of Experience.</h5>
            </div>
          <img src={aboutImg} alt=""  />
        </div>
        <div className="md:w-[600px] w-96 flex flex-col gap-5 md:px-10 px-5 ">
          <div className="font-bold text-lg">
            Axima is a representative Industrial Construction operator providing
            full range of services and soultions in the sphere of Mechanical
            Engineering worldwide.
          </div>
          <div className="">
            The world is changing faster than ever before, business is no
            exception. Axima industries are threatened as technology disrupts
            and software “eats the world.” Yet those that embrace change are
            thriving, building bigger, better, faster, and stronger products
            than ever before. You are helping to lead the charge; we can help
            you build on your past successes and prepare for future.
          </div>
          <div className="text-sm font-bold ">
            <ul className="list-disc">
              <li>Quality Control System, 100% Satisfaction Guarantee</li>
              <li>Highly Professional Staff, Accurate Testing Processes</li>
              <li>Unrivalled workmanship, Professional and Qualified</li>
              <li>Environmental Sensitivity, Personalised solutions</li>
            </ul>
          </div>
          <div>
            <button
              className="font-bold text-lg hover:bg-orange-600 text-orange-600
         border-orange-600 border-2 rounded-xl
         bg-white hover:text-white p-5"
            >
              More About Us
            </button>
          </div>
        </div>
        <div className="md:w-[300px] w-96 flex flex-col md:px-10 px-10
 md:items-center gap-20">
          <div>
            <h1 className="font-extrabold text-7xl">61</h1>
            <p>
              portfolio Completed <br /> In The Last 5 Years
            </p>
          </div>
          <div>
            <h1 className="font-extrabold text-7xl">21</h1>
            <p>
              Happy Customers <br />
              Who Trusted Us
            </p>
          </div>
      
        </div>

</div>
      </div> 
      </div> 
      {/* ...........  */}
    </div>
  );
};

export default Services;
