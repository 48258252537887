import React from "react";
import service1 from "../images/blog-4.jpg";
import service2 from "../images/blog-5.jpg";
import service3 from "../images/blog-1.jpg";

import { BsLink45Deg } from "react-icons/bs";
const Blog = () => {
  return (
    <div>
      <div>
        <div>
          <div className="flex md:flex-row flex-col justify-center items-center flex-wrap gap-20 md:mx-5 mx-5 md:mt-20 md:my-10">
            <div
              class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700"
            >
              <div
                class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img class="rounded-t-lg w-96" src={service1} alt="" />
                <a href="">
                  <div
                    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                  >
                    <BsLink45Deg className=" fill-white text-2xl" />
                  </div>
                </a>
              </div>
              <div class="p-6 md:ml-10    ">
                <div className="flex gap-10">
                  <small>By Steelthemes </small>
                  <small>November 1, 2017 </small>
                </div>
                <h5
                  class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
                >
                  Biomimetics As a Tool <br /> For New Development
                </h5>
                <p class="mb-4 text-black w-72 ">
                  The great explorer of the truth, the master-builder of human
                  happiness seds one rejects, dislikes, avoids pleasure itself,
                  because it …
                </p>
                <hr />
                <div className="flex gap-10">
                  <p>Read More</p>
                  <p>Comment: 0</p>
                </div>
              </div>
            </div>
            <div
              class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700"
            >
              <div
                class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img class="rounded-t-lg w-96" src={service2} alt="" />
                <a href="">
                  <div
                    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                  >
                    <BsLink45Deg className=" fill-white text-2xl" />
                  </div>
                </a>
              </div>
              <div class="p-6 md:ml-10    ">
                <div className="flex gap-10">
                  <small>By Steelthemes </small>
                  <small>November 1, 2017 </small>
                </div>
                <h5
                  class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
                >
                  We Have a Great <br /> Work Environment
                </h5>
                <p class="mb-4 text-black w-72 ">
                  The great explorer of the truth, the master-builder of human
                  happiness seds one rejects, dislikes, avoids pleasure itself,
                  because it …
                </p>
                <hr />
                <div className="flex gap-10">
                  <p>Read More</p>
                  <p>Comment: 0</p>
                </div>
              </div>
            </div>
            <div
              class="block rounded-lg
            bg-white 
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
                dark:bg-neutral-700"
            >
              <div
                class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img class="rounded-t-lg w-96" src={service3} alt="" />
                <a href="">
                  <div
                    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black flex justify-center text-center items-center "
                  >
                    <BsLink45Deg className=" fill-white text-2xl" />
                  </div>
                </a>
              </div>
              <div class="p-6 md:ml-10    ">
                <div className="flex gap-10">
                  <small>By Steelthemes </small>
                  <small>November 1, 2017 </small>
                </div>
                <h5
                  class="mb-2 text-xl font-medium leading-tight text-neutral-800
                dark:text-neutral-50  hover:text-yellow-300"
                >
                  View our Awesome <br /> Manufactures
                </h5>
                <p class="mb-4 text-black w-72 ">
                  The great explorer of the truth, the master-builder of human
                  happiness seds one rejects, dislikes, avoids pleasure itself,
                  because it …
                </p>
                <hr />
                <div className="flex gap-10">
                  <p>Read More</p>
                  <p>Comment: 0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
