import React from "react";
import proj1 from "../images/portfolio-1-585x415.jpg";
import proj2 from "../images/portfolio-2-585x415.jpg";
import proj3 from "../images/portfolio-3-585x415.jpg";
import proj4 from "../images/portfolio-4-585x415.jpg";
import proj5 from "../images/portfolio-5-585x415.jpg";
import proj6 from "../images/portfolio-6-585x415.jpg";
import proj7 from "../images/portfolio-7-585x415.jpg";
import proj8 from "../images/portfolio-8-585x415.jpg";
const Projects = () => {
  return (
    <>
      <div className="bg-black text-white md:py-10 py-5">
        <div className="md:my-20 my-10">
          <div className="flex flex-col justify-center item center">
            <div className=" text-center text-4xl font-bold underline ">
              Latest Projects
            </div>
          </div>
        </div>

        {/* contes  */}

        <div className=" flex justify-center md:flex-row flex-col items-center gap-10 text-gray-100 py-5 ">
          <div className=" w-fit bg-yellow-300 p-2 rounded-md ">View All</div>
          <div className=" hover:bg-yellow-300  w-fit p-2 duration-700 rounded-md ">
            Power &Energy
          </div>
          <div className=" hover:bg-yellow-300  w-fit p-2 duration-700 rounded-md">
            Construction
          </div>
          <div className=" hover:bg-yellow-300  w-fit p-2 duration-700 rounded-md">
            Mining &Planet
          </div>
          <div className=" hover:bg-yellow-300  w-fit p-2 duration-700 rounded-md">
            Mechanical
          </div>
          <div className=" hover:bg-yellow-300  w-fit p-2 duration-700 rounded-md">
            Materials
          </div>
        </div>

        {/* images  */}

        <div className="flex justify-center items-center py-20">
          <div className="flex md:flex-row flex-wrap justify-center items-center flex-col gap-10">
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj1} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>US Gas Company</h1> <br />
                    <p> Power &Energy</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj2} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Pre Construction</h1> <br />
                    <p> Construction</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj3} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Railway Station</h1> <br />
                    <p> Construction</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj4} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Petroleum Tank</h1> <br />
                    <p> Power &Energy</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj5} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Mining Plant Set Up</h1> <br />
                    <p> Construction</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj6} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Van Drilling Service</h1> <br />
                    <p> Mechanical</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj7} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                  <div className="flex flex-col">
                    <h1>Spring Renovation</h1> <br />
                    <p> Materials</p>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="relative overflow-hidden bg-cover flex justify-center items-center bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img class="rounded-t-lg md:w-[300px] w-96" src={proj8} alt="" />
              <a href="">
                <div
                  class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                    overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                    duration-300 ease-in-out hover:opacity-100 rounded-t-lg bg-black border-2 border-yellow-300 flex justify-center text-center items-center "
                >
                    
<div className="flex flex-col">
                    <h1>Los Angeles Bridge</h1> <br />
                    <p> Mining &Planet</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
