import React from "react";

const Choose = () => {
  return (
    <div className="md:my-20 my-10">
      <div className="flex flex-col justify-center item center">
        <div className=" text-center text-4xl font-bold underline ">
          Why Choose Us
        </div>
      </div>

      {/* contet  */}

      <div className=" flex justify-center items-center md:my-20 my-5 ">
        <div className=" flex md:flex-row flex-col gap-10">
          <div className="md:w-[500px] w-96 flex flex-col gap-10 md:px-5 px-10 ">
            <div className=" flex flex-col gap-5">
              <h1 className="font-bold text-xl">Clean Unpolluted</h1>
              <p>
                Again is there anyone who loves or pursues or desires obtain
                pain of itself, because it is pain, but because occasionally.
              </p>
            </div>
            <div className=" flex flex-col gap-5">
              <h1 className="font-bold text-xl">Lasting &Long Term</h1>
              <p>
                Who chooses to enjoy a pleasure that has no or one who avoids a
                pain that produces no resultant pleasure.
              </p>
            </div>
            <div className=" flex flex-col gap-5">
              <h1 className="font-bold text-xl">Easy and Affortable</h1>
              <p>
                Explain to you how all this mistaken idea of denouncing pleasure
                and praising pain was born and will give complete.
              </p>
            </div>
          </div>
          <div className=" bg-slate-50 md:w-[500px] w-96 ">
            <div className="flex flex-col gap-5  py-5  px-5">
              <div className="flex  justify-between">
                <h1>Production</h1>
                <h1>75% </h1>
              </div>
              <div className=" h-5 py-2 bg-slate-200 mx-5 rounded-xl">
                <p className="hidden">ldsv,l;xcm,v</p>
              </div>
            </div>
            <div className="flex flex-col gap-5 py-5  px-5">
              <div className="flex  justify-between">
                <h1>EPC Works</h1>
                <h1>95% </h1>
              </div>
              <div className=" h-5 py-2 bg-slate-200 mx-5 rounded-xl">
                <p className="hidden">ldsv,l;xcm,v</p>
              </div>
            </div>
            <div className="flex flex-col gap-5 py-5  px-5">
              <div className="flex  justify-between">
                <h1>Customer Satisfaction</h1>
                <h1>90% </h1>
              </div>
              <div className=" h-5 py-2 bg-slate-200 mx-5 rounded-xl">
                <p className="hidden">ldsv,l;xcm,v</p>
              </div>
            </div>
            <div className="flex flex-col gap-5 py-5  px-5">
              <div className="flex  justify-between">
                <h1>Utilizaion Rate</h1>
                <h1>100% </h1>
              </div>
              <div className=" h-5 py-2 bg-slate-200 mx-5 rounded-xl">
                <p className="hidden">ldsv,l;xcm,v</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
