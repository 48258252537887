import React from "react";
import port1 from '../images/1 (4).804022b903e306ae1e47.jpg'
import port2 from '../images/c3.5cc5ce83886238bca859.jpg'
import port3 from '../images/c.35513f5fae93734ec340.jpg'
const Portfolio = () => {
  return (
    <div className="' flex justify-center items-center">
      <div className="max-w-[1000px]">
        {/* hedaer start  */}
        <div className="md:my-20 my-10">
          <p className=" text-red-500 font-bold">
            We Work With Global Industries!
          </p>
          <p className="font-bold text-5xl">Featured Portfolio</p>
        </div>
        {/* header end  */}
        <div className="flex md:flex-row flex-col gap-10 md:mx-5 mx-5 md:mt-20">
          <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div
              class="relative overflow-hidden bg-cover bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img
                class="rounded-t-lg"
                src={port1}
                alt=""
              />
              <a href="">
                <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                 overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                  duration-300 ease-in-out hover:opacity-100 flex justify-center text-center items-center ">
                  <p className="bg-red-500 w-20 h-20 flex items-center justify-center
                  font-bold text-white text-center rounded-full">
                  View
                    </p>  
                  </div>
              </a>
            </div>
            <div class="p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800
               dark:text-neutral-50 hover:text-red-500">
              Floride Chemicals Factory
              </h5>
              <p class="mb-4 text-base text-orange-600">
              Chemicals,oil & Gas
              </p>
             
            </div>
          </div>
          <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div
              class="relative overflow-hidden bg-cover bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img
                class="rounded-t-lg"
                src={port1}
                alt=""
               
              />
              <a href="">
                <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                 overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                  duration-300 ease-in-out hover:opacity-100 flex justify-center text-center items-center ">
                  <p className="bg-red-500 w-20 h-20 flex items-center justify-center
                  font-bold text-white text-center rounded-full">
                  View
                    </p>  
                  </div>
              </a>
            </div>
            <div class="p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800
               dark:text-neutral-50 hover:text-red-500">
             Highway Energy Station
              </h5>
              <p class="mb-4 text-base text-orange-600">
              Construction , Energy
              </p>
             
            </div>
          </div>
          <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div
              class="relative overflow-hidden bg-cover bg-no-repeat"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img
                class="rounded-t-lg"
                src={port1}
                alt=""
              />
              <a href="">
                <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                 overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition
                  duration-300 ease-in-out hover:opacity-100 flex justify-center text-center items-center ">
                  <p className="bg-red-500 w-20 h-20 flex items-center justify-center
                  font-bold text-white text-center rounded-full">
                  View
                    </p>  
                  </div>
              </a>
            </div>
            <div class="p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800
               dark:text-neutral-50 hover:text-red-500">
            Villy Manufacturing Inc
              </h5>
              <p class="mb-4 text-base text-orange-600">
              Engineering,Auto
              </p>
             
            </div>
          </div>

        
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
